import React from 'react';
import { Helmet } from 'react-helmet';

const MetaTitleDesc = ({ MetaTitle, MetaDesc, Translate }) => {

  return (
    <div>
      <Helmet>
        <title translate={Translate}>{MetaTitle}</title>
        <meta name="description" translate={Translate} content={MetaDesc} />
        {/* {currentUrl && <link rel="canonical" href={currentUrl} />} */}
      </Helmet>
    </div>
  );
};

export default MetaTitleDesc;
