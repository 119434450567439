import React, {useEffect} from 'react';
import './assets/css/style.css';
import Layouts from './Layouts/Layouts'

const App = () => {
  return (
    <>
      <div>
        <Layouts />
      </div>
    </>
  )
}

export default App