import React, {useState} from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const PunctuationSpace = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("Punctuation Space Copied");
  };
  return (
    <div className="notranslate" translate="no">
    <MetaTitleDesc Translate="no" MetaTitle="Punctuation Space (U+2008) Character Copy and Paste" MetaDesc="Punctuation Space (U+2008) Character Copy and Paste" />
      <Container>
        <div className='mb-4'>
          <p className="fs-17">
          The Unicode character U+2008 represents the "Punctuation Space," which is a whitespace character used to create space between punctuation marks in a text.

          </p>
          
        </div>
        <div className="text-center">
          <h4 className="fs-18 fw-700">Copy Punctuation Space</h4>
          <p className="fs-17">You can copy Punctuation Space here and use it wherever you like</p>
          <input hidden type="text" value={inputValue} />
          <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
            value="Copiar">
             Click Here to Copy Punctuation Space (ㅤ)
          </Button>
        </div>
        <Row className="justify-content-center my-3">
          <Col md="8">
            <div className="table-responsive">
              <table className="table table-bordered">
                {/* <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead> */}
                <tbody>
                <tr>
                    <th>Unicode</th>
                    <td> U+2008 </td>
                  </tr>
                  <tr>
                    <th>HTML Entity</th>
                    <td> {"&#8200;"} or {"&puncsp;"}  </td>
                  </tr>
                  <tr>
                    <th>LateX</th>
                    <td>Not a standard command</td>
                  </tr>
                  <tr>
                    <th>CSS</th>
                    <td>\2008</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default PunctuationSpace;
