import React from 'react'
import Routers from '../Routers'
// import Header from './Header'
import Footer from './Footer'

const Layouts = () => {
  return (
    <>
      {/* <Header translateTo={translateTo} Languages={Languages} /> */}
      <div className='py-5'><Routers/></div>
      <Footer />
    </>
  )
}

export default Layouts