import React, {useState} from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const LineSeparator = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("Line Separator Copied");
  };
  return (
    <div className="notranslate" translate="no">
    <MetaTitleDesc Translate="no" MetaTitle="Line Separator (U+2028) Character Copy and Paste" MetaDesc="Line Separator (U+2028) Character Copy and Paste" />
      <Container>
        <div className='mb-4'>
          <p className="fs-17">
          The Line Separator character, U+2028, is primarily recognized and utilized within programming contexts such as JavaScript and JSON. In these contexts, the Line Separator is essential for denoting the end of a line.

          </p>
        </div>
        <div className="text-center">
          <h4 className="fs-18 fw-700">Copy Line Separator</h4>
          <p className="fs-17">You can copy Line Separator here and use it wherever you like</p>
          <input hidden type="text" value={inputValue} />
          <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
            value="Copiar">
           Click Here to Copy Line Separator (ㅤ)
          </Button>
        </div>
        <Row className="justify-content-center my-3">
          <Col md="8">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                    <td>U+2028</td>
                    <td>Line Separator</td>
                    <td>[]</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      
      </Container>
    </div>
  );
};

export default LineSeparator;
