import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import MultiLang from './Pages/MultiLang'
// import Contact from './Pages/Contact'
// import About from './Pages/About'
import Space from './Pages/Space'
import NoBreakSpace from './Pages/NoBreakSpace'
import EnQuad from './Pages/EnQuad'
import EmQuad from './Pages/EmQuad'
import EnSpace from './Pages/EnSpace'
import EmSpace from './Pages/EmSpace'
import ThreePerEmSpace from './Pages/ThreePerEmSpace'
import FourPerEmSpace from './Pages/FourPerEmSpace'
import SixPerEmSpace from './Pages/SixPerEmSpace'
import FigureSpace from './Pages/FigureSpace'
import PunctuationSpace from './Pages/PunctuationSpace'
import ThinSpace from './Pages/ThinSpace'
import HairSpace from './Pages/HairSpace'
import LineSeparator from './Pages/LineSeparator'
import MediumMathematicalSpace from './Pages/MediumMathematicalSpace'
import IdeographicSpace from './Pages/IdeographicSpace'
// import ErrorPage from './Pages/ErrorPage'

const Routers = () => {

  return (
    <Routes basename="/">
      <Route path="/" element={<Home language="en" />} />
      <Route path="/home" element={<Navigate to="/" language="en" />} />
      <Route path="/en" element={<MultiLang language="en" />} />
      <Route path="/es" element={<MultiLang language="es" />} />
      <Route path="/fi" element={<MultiLang language="fi" />} />
      <Route path="/pl" element={<MultiLang language="pl" />} />
      <Route path="/it" element={<MultiLang language="it" />} />
      <Route path="/sv" element={<MultiLang language="sv" />} />
      <Route path="/ca" element={<MultiLang language="ca" />} />
      <Route path="/fr" element={<MultiLang language="fr" />} />
      <Route path="/ka" element={<MultiLang language="ka" />} />
      <Route path="/pt" element={<MultiLang language="pt" />} />
      <Route path="/th" element={<MultiLang language="th" />} />
      <Route path="/cs" element={<MultiLang language="cs" />} />
      <Route path="/iw" element={<MultiLang language="iw" />} />
      <Route path="/lt" element={<MultiLang language="lt" />} />
      <Route path="/ro" element={<MultiLang language="ro" />} />
      <Route path="/tr" element={<MultiLang language="tr" />} />
      <Route path="/lv" element={<MultiLang language="lv" />} />
      <Route path="/ru" element={<MultiLang language="ru" />} />
      <Route path="/uk" element={<MultiLang language="uk" />} />
      <Route path="/de" element={<MultiLang language="de" />} />
      <Route path="/hr" element={<MultiLang language="hr" />} />
      <Route path="/mk" element={<MultiLang language="mk" />} />
      <Route path="/sk" element={<MultiLang language="sk" />} />
      <Route path="/vi" element={<MultiLang language="vi" />} />
      <Route path="/el" element={<MultiLang language="el" />} />
      <Route path="/hu" element={<MultiLang language="hu" />} />
      <Route path="/ms" element={<MultiLang language="ms" />} />
      <Route path="/sl" element={<MultiLang language="sl" />} />
      <Route path="/zh-CN" element={<MultiLang language="zh-CN" />} />
      <Route path="/space" element={<Space />} />
      <Route path="/no-break-space" element={<NoBreakSpace />} />
      <Route path="/en-quad" element={<EnQuad />} />
      <Route path="/em-quad" element={<EmQuad />} />
      <Route path="/en-space" element={<EnSpace />} />
      <Route path="/em-space" element={<EmSpace />} />
      <Route path="/three-per-em-space" element={<ThreePerEmSpace />} />
      <Route path="/four-per-em-space" element={<FourPerEmSpace />} />
      <Route path="/six-per-em-space" element={<SixPerEmSpace />} />
      <Route path="/figure-space" element={<FigureSpace />} />
      <Route path="/punctuation-space" element={<PunctuationSpace />} />
      <Route path="/thin-space" element={<ThinSpace />} />
      <Route path="/hair-space" element={<HairSpace />} />
      <Route path="/line-separator" element={<LineSeparator />} />
      <Route path="/medium-mathematical-space" element={<MediumMathematicalSpace />} />
      <Route path="/ideographic-space" element={<IdeographicSpace />} />
      {/* <Route path="/about-us" element={<About />} /> */}
      {/* <Route path="/contact-us" element={<Contact />} /> */}
      {/* <Route path="404" element={<ErrorPage />} />
        <Route path="*" element={<Navigate to="/404" />} /> */}
    </Routes>
  )
}

export default Routers