import React, {useState} from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const HairSpace = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("Hair Space Copied");
  };
  return (
    <div className="notranslate" translate="no">
    <MetaTitleDesc Translate="no" MetaTitle="Hair Space (U+200A) Character Copy and Paste" MetaDesc="Hair Space (U+200A) Character Copy and Paste" />
      <Container>
        <div className='mb-4'>
          <p className="fs-17">
          The Unicode character U+200A represents the "Hair Space," which is an extremely narrow whitespace character. It is narrower than a standard space (U+0020) and serves the purpose of providing an extremely fine and subtle amount of space between characters or elements.
          </p>
        
        </div>
        <div className="text-center">
          <h4 className="fs-18 fw-700">Copy Hair Space</h4>
          <p className="fs-17">You can copy Hair Space here and use it wherever you like</p>
          <input hidden type="text" value={inputValue} />
          <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
            value="Copiar">
            Click Here to Copy Hair Space (ㅤ)
          </Button>
        </div>
        <Row className="justify-content-center my-3">
          <Col md="8">
            <div className="table-responsive">
              <table className="table table-bordered">
                {/* <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead> */}
                <tbody>
                <tr>
                    <th>Unicode</th>
                    <td> U+2000A </td>
                  </tr>
                  <tr>
                    <th>HTML Entity</th>
                    <td> {"&#8202;"} or {"&hairsp;"}  </td>
                  </tr>
                  <tr>
                    <th>LateX</th>
                    <td>{"\hspace{0.16667em}"} </td>
                  </tr>
                  <tr>
                    <th>CSS</th>
                    <td>\200A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
};

export default HairSpace;
