import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const NoBreakSpace = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("No-Break Space Copied");
  };
  return (
    <div>
    <MetaTitleDesc Translate="no" MetaTitle="non-breaking space (NBSP) Character Copy and Paste" MetaDesc="non-breaking space (NBSP) Character Copy and Paste" />
      <Container>
        <div className='mb-4'>
          <p className="fs-17">
          A non-breaking space (NBSP) is a special character used in word processing and web-based texts to create space that prevents adjacent words or characters from being separated at the end of a line.</p>
        </div>
        <div className="text-center">
          <h4 className="fs-18 fw-700">Copy NO-BREAK SPACE</h4>
          <p className="fs-17">You can copy no-break space here and use it wherever you like</p>
          <input hidden type="text" value={inputValue} />
          <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
            value="Copiar">
            Click Here to Copy NO-BREAK SPACE (ㅤ)
          </Button>
        </div>
        <Row className="justify-content-center my-3">
          <Col md="8">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className='table-dark'>
                <tr>
                    <th>Format </th>
                    <th>Representation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Unicode</th>
                    <td>U+00A0</td>
                  </tr>
                  <tr>
                    <th>HTML Entity</th>
                    <td>&nbsp</td>
                  </tr>
                  <tr>
                    <th>CSS</th>
                    <td>\00A0</td>
                  </tr>
                  <tr>
                    <th>LateX</th>
                    <td> ~</td>
                  </tr>
                  <tr>
                    <th>Windows Alt code</th>
                    <td>Alt + 0160</td>
                  </tr>
                  <tr>
                    <th>macOS</th>
                    <td>Option + Spacebar</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default NoBreakSpace;
