import React, { useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const EnQuad = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("En Quad Copied");
  };
  return (
    <div className="notranslate" translate="no">
    <MetaTitleDesc Translate="no" MetaTitle="En Quad (U+2000) Character Copy and Paste" MetaDesc="En Quad (U+2000) Character Copy and Paste" />
      <Container>
        <div className='mb-4'>
          <p className="fs-17">
          The Unicode character U+2000 is known as the "En Quad." It belongs to the block of General Punctuation within the Unicode standard. It is a whitespace character that is one en (half the width of an em) in width. In typography, an en is a typographic unit that is approximately the width of the capital letter "N."
          </p>
        </div>
        <div className="text-center">
          <h4 className="fs-18 fw-700">Copy En Quad</h4>
          <p className="fs-17">You can copy En Quad here and use it wherever you like</p>
          <input hidden type="text" value={inputValue} />
          <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
            value="Copiar">
            Click Here to Copy En Quad (ㅤ)
          </Button>
        </div>
        <Row className="justify-content-center my-3">
          <Col md="8">
            <div className="table-responsive">
              <table className="table table-bordered">
                {/* <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead> */}
                <tbody>
                <tr>
                    <th>Unicode</th>
                    <td> U+2000 </td>
                  </tr>
                  <tr>
                    <th>HTML Entity</th>
                    <td> {"&nbsp;"} </td>
                  </tr>
                  <tr>
                    <th>LateX</th>
                    <td>\quad</td>
                  </tr>
                  <tr>
                    <th>CSS</th>
                    <td>\2000</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        
      </Container>
    </div>
  );
};

export default EnQuad;
