import React, {useState} from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import MetaTitleDesc from '../MetaTitleDesc'
import { toast } from 'react-toastify';

const EmQuad = () => {
  const [inputValue, setInputValue] = useState(' ');

  const handleCopy = () => {
    navigator.clipboard.writeText(inputValue);
    toast.success("Em Quad Copied");
  };
  return (
    <div className="notranslate" translate="no">
    <MetaTitleDesc Translate="no" MetaTitle="Em Quad (U+2001) Character Copy and Paste" MetaDesc="Em Quad (U+2001) Character Copy and Paste" />
      <Container>
        <div className='mb-4'>
          <p className="fs-17">
          The Unicode character U+2001 is referred to as the "Em Quad," belonging to the General Punctuation block within the Unicode standard. An Em Quad is a whitespace character that is one em in width. In typography, an em is a typographic unit that is roughly equivalent to the width of the capital letter "M."
          </p>
        </div>
        <div className="text-center">
          <h4 className="fs-18 fw-700">Copy Em Quad</h4>
          <p className="fs-17">You can copy Em Quad here and use it wherever you like</p>
          <input hidden type="text" value={inputValue} />
          <Button variant="secondary" onClick={handleCopy} className='mb-3 fs-18 theme-btn border-0' id="boton3"
            value="Copiar">
            Click Here to Copy Em Quad (ㅤ)
          </Button>
        </div>
        <Row className="justify-content-center my-3">
          <Col md="8">
            <div className="table-responsive">
              <table className="table table-bordered">
                {/* <thead className='table-dark'>
                  <tr>
                    <th>Unicode</th>
                    <th>Unicode Names</th>
                    <th>Copy</th>
                  </tr>
                </thead> */}
                <tbody>
                <tr>
                    <th>Unicode</th>
                    <td> {"&#32;"} </td>
                  </tr>
                  <tr>
                    <th>HTML Entity</th>
                    <td>{"&emsp;"}</td>
                  </tr>
                  <tr>
                    <th>LateX</th>
                    <td>\qquad</td>
                  </tr>
                  <tr>
                    <th>CSS</th>
                    <td>\2001</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmQuad;
