import React, { useState } from 'react';
import { Container, Alert, Card, InputGroup, Form, Button, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import Whatsapp from '../assets/images/whatsapp.png';
import Discord from '../assets/images/discord.png';
import MetaTitleDesc from '../MetaTitleDesc';

const Home = () => {
  const [inputValue, setInputValue] = useState('');
  const [generatedText, setGeneratedText] = useState('');

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
  };

  const generateCharacters = () => {
    const inputValueAsNumber = parseInt(inputValue);
    if (!isNaN(inputValueAsNumber) && inputValueAsNumber >= 1 && inputValueAsNumber <= 10000) {
      const invisibleText = 'ㅤ'.repeat(inputValueAsNumber); // Zero Width Space character
      setGeneratedText(invisibleText);
    } else {
      alert('Failed to Copy your text');
    }
  };

  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = generatedText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Your Invisible Text is Copied');
  };

  return (
    <div>
      <MetaTitleDesc MetaTitle="Invisible Text (  ) Invisible Character [ ] Copy and Paste" MetaDesc="Invisible Character skyrocket your content with copy paste invisible text. character, empty text, and blank text. Use your invisible text messages on whatsapp, twitter, instagram, etc." />
      <Container>
        <Alert variant="warning">
          <div className="text-center">
            <strong>INVISIBLE TEXT</strong> Copy and Paste
          </div>
        </Alert>
        <Row>
          <Col md="8">
            <div className='mb-4'>
              <h2 className="fs-25 my-4 text__blue fw-700">Invisible Text: A Journey of Discovery</h2>
              <p className="fs-17">
                An <strong>invisible character</strong> is nothing but a blank white space. These characters are normally used to represent <strong>blank space</strong> and to send an empty message. Because of its characteristics, the system detects the <strong>empty character</strong>, but the viewer can't see the <strong>invisible space</strong> through their eyes.
              </p>
              <p className="fs-17">Invisible text characters, blank space, blank Text, empty characters and invisible letters copy and paste with <strong>Unicode</strong>. An invisible character or invisible text letter, you can copy easily for your related purposes.</p>
              <p className="fs-17">Copy the blank character and use it anywhere, like WhatsApp messages, Facebook, Twitter, Instagram, discord, and TikTok. Also, use multiple invisible space letters or text generators to generate multiple invisible text characters.</p>
              <Row className="justify-content-center my-3">
                <Col md="8">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className='table-dark'>
                        <tr>
                          <th>Unicode</th>
                          <th>Unicode Names</th>
                          <th>Copy</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>U+0020</td>
                          <td><NavLink to="/space/">Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+00A0</td>
                          <td><NavLink to="/no-break-space/">No-Break Space</NavLink></td>
                          <td>[&nbsp;]</td>
                        </tr>
                        <tr>
                          <td>U+2000</td>
                          <td><NavLink to="/en-quad/">En Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2001</td>
                          <td><NavLink to="/em-quad/">Em Quad</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2002</td>
                          <td><NavLink to="/en-space/">En Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2003</td>
                          <td><NavLink to="/em-space/">Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2004</td>
                          <td><NavLink to="/three-per-em-space/">Three-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2005</td>
                          <td><NavLink to="/four-per-em-space/">Four-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2006</td>
                          <td><NavLink to="/six-per-em-space/">Six-Per-Em Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2007</td>
                          <td><NavLink to="/figure-space/">Figure Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2008</td>
                          <td><NavLink to="/punctuation-space/">Punctuation Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2009</td>
                          <td><NavLink to="/thin-space/">Thin Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+200A</td>
                          <td><NavLink to="/hair-space/">Hair Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+2028</td>
                          <td><NavLink to="/line-separator/">Line Separator</NavLink></td>
                          <td>[]</td>
                        </tr>
                        <tr>
                          <td>U+205F</td>
                          <td><NavLink to="/medium-mathematical-space/">Medium Mathematical Space</NavLink></td>
                          <td>[ ]</td>
                        </tr>
                        <tr>
                          <td>U+3000</td>
                          <td><NavLink to="/ideographic-space/">Ideographic Space</NavLink></td>
                          <td>[　]</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
              <h4 className="fs-18 fw-700">Overview</h4>
              <p className="fs-17">Let's get started; Invisible Text, as its name tells us, invisible, is a type of Text that does not appear on the screen. Many people have a question in mind if i:t is not showing on screen, why are we using it? So, to answer this question, we have to dig into its necessity.</p>
              <p className="fs-17">Invisible Text is used to represent a ‘Blank Space’ and to send an ‘Empty Message’. It also creates an invisible space between two letters. As for its characteristics, the system detects the empty space, but on the other hand, the viewer can't see it.</p>
            </div>
            <div>
              <h4 className="fs-18 fw-700">Universal Code Set</h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Whatsapp} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">The space, white space or empty character is composed of a Unicode. Unicode stands for “<strong>Universal Coded Character Set</strong>”. When a person sends an empty message or uses blank space, this message has Unicode like U+0200, U+3164 and more. There are thousands of Unicode available on different websites. </p>
              <h4 className="fs-18 fw-700">Purpose of Invisible Text </h4>
              <Row className="justify-content-center ">
                <Col md="8">
                  <img src={Discord} alt="" className="img-fluid mb-3" />
                </Col>
              </Row>
              <p className="fs-17">A person can use them without any complications. It requires just a moment to generate and send.
              </p>
              <p className="fs-17">These codes are most commonly used for social media and gaming purposes.  </p>
              <p className="fs-17">In social media, they used to make profile IDs nameless. This is because social media have some drawbacks. The social media uses it for its friends and followers. </p>
              <p className="fs-17">On the other hand, in gaming prospects, it is used due to invisible functionality. Some online games like Free Fire do not accept spaces in usernames. Therefore, gamers use them to make usernames. It justifies their unique username.</p>
              <p className="fs-17">From the above discussion, we can consider Blank Text to be false. But in fact, all the symbols are together, and it also generates a space effect.</p>
              <h4 className="fs-18 fw-700">Features of Invisible Text</h4>
              <p className="fs-17">As we know, hidden Text is very useful. It is now used very commonly because of its features.</p>
              <p className="fs-17">Its amazing features are;</p>
              <h4 className="fs-18 fw-700">Easy to use</h4>
              <p className="fs-17">This empty character is very easy to use and is user-friendly. Anyone can use this tool easily. You only have to copy the Text and paste where ever you want to.</p>

              <h4 className="fs-18 fw-700">Premium Interface</h4>
              <p className="fs-17">It’s best because of its premium user interface. The user interface is very important. You can use it the whole day but never feel tired.</p>

              <h4 className="fs-18 fw-700">Multiple Methods Availablem</h4>

              <p className="fs-17">Everyone in this world has their own way of doing work. They do one work with different methods. They use methods which they like. Therefore, it also gives you two methods, and they are</p>

              <ul className="list-unstyled">
                <li><strong>Method 1: </strong>Create a button</li>
                <li><strong>Method 2: </strong>Create manually</li>
              </ul>
              <p className="fs-17">Let's take a look at these two methods one by one.</p>
              <h4 className="fs-18 fw-700">Method 1</h4>
              <p className="fs-17">Method 1 is very easy. It's just a copy-and-paste method. Anyone can easily do that. In this method:</p>
              <ul>
                <li>Click the copy button</li>
                <li>An invisible letter is copied to your clipboard.</li>
                <li>Then paste this copied Text anywhere you want</li>
                <li>It sometimes doesn't work on some devices. If this doesn’t work on your device, use Method 2 instead.</li>
              </ul>
              <h4 className="fs-18 fw-700">Method 2</h4>
              <p className="fs-17">Method 2 is also easy to use. In this method:</p>
              <ul>
                <li>You can test the empty Text before pasting it in your required place.</li>
                <li>You can test the empty Text before pasting it in your required place.</li>
                <li>Manually select and copy the Text.</li>
                <li>Blank Text will be selected.</li>
                <li>Then press (Ctrl+c) to copy. </li>
                <li>Now, you can paste the copied Text anywhere you want.</li>
                <li>Both methods are easy, and their usage depends on the user.</li>
              </ul>
              <h4 className="fs-18 fw-700">Free to use</h4>
              <p className="fs-17">This tool is the free version. Anyone can use it without any charges or fees. Its free version makes it unique among its crowd.</p>
              <h4 className="fs-18 fw-700">Multiple Languages options</h4>
              <p className="fs-17">This tool generally comes in English Language. However, this tool is available in French, Portuguese and many other languages. So you can use it without any language barrier.</p>
              <h4 className="fs-18 fw-700">Copy and Paste feature.</h4>
              <p className='fs-17'>The main feature which is used abundantly is the copy & paste feature. All you have to do is copy your Text and paste it.</p>
              <h4 className="fs-18 fw-700">Uses of Invisible Text</h4>
              <p className="fs-17">Invisible text letters can be used on many platforms or in numerous situations. It is not designed for single-purpose usage. It is designed for a user to use it anywhere. But, in general, most people commonly use it on social media platforms and on gaming platforms. There is no limit to using it, only social media or gaming, but you can use it anywhere.</p>
              <p className="fs-17">You can use Invisible Text for various purposes:</p>
              <h4 className="fs-18 fw-700">Invisible Text for Fortnite Purposes</h4>
              <p className="fs-17">In Fortnite, the invisible letter is used for Fortnite RPG to hide usernames. You can easily use an invisible text generator tool.</p>
              <h4 className="fs-18 fw-700">Invisible Text for Instagram, Twitter and Pubg</h4>
              <p className="fs-17">As I mentioned earlier about its usage on social media platforms and for games like Pubg.</p>
              <p className="fs-17">This empty space is also used to create usernames for Twitter and Instagram. However, it is also used to create unique usernames in Pubg and other mobile apps.</p>
              <p className="fs-17">Sometimes, people also use Fake Name Generator to create fake social media and gaming profiles to hide their identities.</p>
              <h4 className="fs-18 fw-700">Invisible Text for sending an empty message on WhatsApp</h4>
              <p className="fs-17">Online texting message applications do not allow to a send an empty message. You can generate an empty space character by using an invisible text generator and send it on WhatsApp.</p>
              <p className="fs-17">You can also use it to send an empty message on your mobile’s default message app.
              </p>
              <h4 className="fs-18 fw-700">Final Thoughts</h4>
              <p className="fs-17">Invisible Text, with its multifaceted applications, has become a powerful tool in various domains. </p>
              <p className="fs-17">As technology advances, the prospects of invisible Text hold exciting possibilities, ranging from improved communication privacy to innovative applications in emerging technologies. </p>
              <p className="fs-17">Embracing this hidden dimension of information opens doors to a world where messages are not always what they seem, inviting individuals to explore beyond the visible.</p>

            </div>
          </Col>
          <Col md="4">
            <Card className="my-3">
              <Card.Body className="text-center">
                {/* <Row className="justify-content-center">
                  <Col md="4"> */}
                <InputGroup className="mb-3">
                  <Form.Control
                    type="number"
                    aria-label="invisibleText"
                    className="shadow-none fs-17"
                    aria-describedby="invisibleText"
                    min="1"
                    max="10000"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <Button variant="secondary" className="theme-btn border-0" onClick={generateCharacters}>
                    Trigger
                  </Button>
                </InputGroup>
                <Form.Group>
                  <Form.Control as="textarea" name="name"
                    id="texto2"
                    className='shadow-none mb-3'
                    value={generatedText}
                    readOnly />
                </Form.Group>
                <Button variant="secondary" className='mb-3 theme-btn border-0' id="boton3"
                  value="Copiar"
                  onClick={copyToClipboard}>
                  Copy
                </Button>
                {/* </Col>
                </Row> */}
                <Card.Text>To copy invisible text with spaces, click the Copy button.</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <script type="application/ld+json">
        {`{
            "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Invisible Text",
          "url": "https://invisibletext.net/",
          "potentialAction": {
            "@type": "SearchAction",
          "target": "https://invisibletext.net/search/{search_term_string}",
          "query-input": "required name=search_term_string"
            }
          }`}
      </script>

      <script type="application/ld+json">
        {`{
          "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Invisible Text",
        "url": "https://invisibletext.net/",
        "logo": ""
        }`}
      </script>

    </div>
  );
};

export default Home;
